jQuery(function($) {
  $('.slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: slider.hasClass('has-dots'),
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: true,
      imagesLoaded: true,
      cellAlign: 'left',
      autoPlay: 2500,
      contain: false
    }

    slider.flickity(config)
  })


  $('.testimonial-slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: slider.hasClass('has-dots'),
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: true,
      imagesLoaded: true,
      contain: false
    }

    slider.flickity(config)
  })


  $('.gallery-slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: slider.hasClass('has-dots'),
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: true,
      imagesLoaded: true,
      cellAlign: 'right',
      contain: false
    }

    slider.flickity(config)
  })


  $('.featured-service-slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: slider.hasClass('has-dots'),
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: true,
      imagesLoaded: true,
      autoPlay: 2500,
      cellAlign: 'left',
      contain: false
    }

    slider.flickity(config)
  })
})