jQuery(document).ready(function($) {
  require('./components/mobile-header.js');
  require('./components/slider.js');
  require('./components/header-scroll.js');
});


function equipment(x) {
  if (x.matches) {
    jQuery(document).ready(function(){
      jQuery('.equipment-item').click(function(event){
        jQuery('.active').removeClass('active');

        jQuery(this).addClass('active');

        event.preventDefault();
      });
    });
  }
}

var x = window.matchMedia("(max-width: 1024px)");
equipment(x);